exports.components = {
  "component---src-components-course-pages-template-tsx": () => import("./../../../src/components/coursePages/template.tsx" /* webpackChunkName: "component---src-components-course-pages-template-tsx" */),
  "component---src-components-legal-template-tsx": () => import("./../../../src/components/legal/template.tsx" /* webpackChunkName: "component---src-components-legal-template-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-us-about-about-us-tsx": () => import("./../../../src/pages/us/about/about-us.tsx" /* webpackChunkName: "component---src-pages-us-about-about-us-tsx" */),
  "component---src-pages-us-about-contact-us-tsx": () => import("./../../../src/pages/us/about/contact-us.tsx" /* webpackChunkName: "component---src-pages-us-about-contact-us-tsx" */),
  "component---src-pages-us-business-accounts-tsx": () => import("./../../../src/pages/us/business/accounts.tsx" /* webpackChunkName: "component---src-pages-us-business-accounts-tsx" */),
  "component---src-pages-us-courses-tsx": () => import("./../../../src/pages/us/courses.tsx" /* webpackChunkName: "component---src-pages-us-courses-tsx" */),
  "component---src-pages-us-index-tsx": () => import("./../../../src/pages/us/index.tsx" /* webpackChunkName: "component---src-pages-us-index-tsx" */)
}

